import { Row } from '@tanstack/react-table';
import { useTheme } from '@mui/material';
import { VirtualItem } from '@tanstack/react-virtual';
import CatalogTableCell from './CatalogueTableCell';
import { CatalogItem } from 'slices/catalogSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';

const CatalogTableRow = ({
  highlightedRowIndex,
  setHighlightedRowIndex,
  virtualRow,
  row,
  isForwardCurves
}: {
  highlightedRowIndex: number | null;
  setHighlightedRowIndex: (index: number | null) => void;
  virtualRow: VirtualItem;
  row: Row<CatalogItem>;
  isForwardCurves: boolean;
}) => {
  const {
    original: { Last, Change }
  } = row;
  const { isSymbolSelected } = useSelectionFunctions();

  const { palette } = useTheme();

  const isRestrictedData = !Last && !Change;

  const isHighlighted = virtualRow.index === highlightedRowIndex;

  const returnRowBgColor = () => {
    if (isHighlighted && isForwardCurves) return palette.table.forwardCurve;
    if (isSymbolSelected(row.getValue('Symbol'))) return palette.table.spot;
    if (isHighlighted) return palette.table.highlight;
    if (isRestrictedData && !isForwardCurves) return palette.table.infoRowBg;
    if (virtualRow.index % 2 === 0) return palette.secondary.main;
    if (virtualRow.index % 2 !== 0) return palette.background.paper;
  };

  const handleRowClick = (e: any) => {
    if (e.target.type !== 'checkbox')
      setHighlightedRowIndex(isHighlighted ? null : virtualRow.index);
  };

  return (
    <div
      data-index={virtualRow.index}
      key={row.id + virtualRow.index}
      style={{
        display: 'flex',
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`,
        width: '100%',
        height: '23px',
        padding: 0,
        paddingLeft: '8px',
        backgroundColor: returnRowBgColor()
      }}
      onClick={handleRowClick}
    >
      {row.getVisibleCells().map(cell => {
        return (
          <CatalogTableCell
            key={row.id + cell.id}
            cell={cell}
            isForwardCurves={isForwardCurves}
            isRestrictedData={isRestrictedData}
            palette={palette}
          ></CatalogTableCell>
        );
      })}
    </div>
  );
};

export default CatalogTableRow;
