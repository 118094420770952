import { Box, TextField, Button, Typography, Link, ButtonBase } from '@mui/material';
import useLogin from './useLogin';
import GoLogo from 'components/GoLogo';
import DefaultLayout from 'layouts/DefaultLayout';

export const ThanksMessage = () => {
  const fontStyle = { mb: 2, fontSize: 24 };
  return (
    <Box>
      <Typography sx={fontStyle}>Thank you! </Typography>
      <Typography sx={fontStyle}>We’ll be in touch shortly!</Typography>
    </Box>
  );
};
const Login = () => {
  const {
    handleChangeEmail,
    handleOnKeyDown,
    status,
    logoExpanded,
    loggedIn,
    error,
    handleLogin,
    email,
    resetPage
  } = useLogin();

  const loading = status === 'loading';
  const fontStyle = { mb: 2, fontSize: 24 };

  const renderActionComponent = () => {
    if (loggedIn)
      return (
        <>
          <Typography sx={fontStyle}>You've been logged in.</Typography>
          <Typography sx={fontStyle}>You can now close this window.</Typography>
        </>
      );

    if (status === 'fulfilled' && !error)
      return (
        <>
          <Typography sx={fontStyle}>We've sent a link to your email address</Typography>
          <Typography sx={{ mb: 4, fontSize: 24, fontWeight: 700 }}>{email}</Typography>
          <Typography sx={{ mb: 3, fontSize: 24 }}>Please check your inbox.</Typography>
          <Typography>
            If you don't see the email, please check your spam folder or&nbsp;
            <ButtonBase
              sx={{ textDecoration: 'underline', fontSize: 16, mb: '3px' }}
              onClick={resetPage}
            >
              try resending
            </ButtonBase>
            .
          </Typography>
        </>
      );

    if (status === 'failed' && !error) return <ThanksMessage />;

    return (
      <>
        <Typography sx={fontStyle}>Welcome to GX Go!</Typography>
        <Typography sx={{ mb: 3 }}>To login, please enter your email address</Typography>
        <Box
          minHeight={84}
          width={360}
        >
          <TextField
            variant='filled'
            fullWidth
            type='email'
            autoComplete='email'
            id='email'
            label='Email'
            name='email'
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            onKeyDown={handleOnKeyDown}
            value={email}
            onChange={handleChangeEmail}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
        </Box>
        <Button
          fullWidth
          variant='contained'
          sx={{
            mt: 1,
            mb: 2,
            color: 'background.default',
            fontWeight: 'bold',
            fontSize: 18
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
        <Typography>
          New to GX Go?{' '}
          <Link
            href='https://www.general-index.com/signup'
            target='_blank'
            rel='noopener noreferrer'
            color='primary.main'
            sx={{ textDecoration: 'none' }}
          >
            Request a demo
          </Link>{' '}
          today.
        </Typography>
      </>
    );
  };

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 'fit-content',
          mt: '8%'
        }}
      >
        <GoLogo open={logoExpanded} />
        <Box sx={{ maxWidth: '550px', mt: 3 }}>{renderActionComponent()}</Box>
      </Box>
    </DefaultLayout>
  );
};

export default Login;
