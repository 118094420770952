import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import httpInstances from 'api/axiosInstance';

const CONFIG_NAME = 'userCreation';

export type User = {
  email: string;
  hs_firstname: string;
  hs_lastname: string;
  hs_jobtitle: string;
  hs_company: string;
  prefMarket: string;
};

type UserCreationState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
};

type ErrorResponse = {
  message: string;
};

const initialState: UserCreationState = {
  status: 'idle',
  error: null
};
type Request = { user: User; captchaToken: string };

export const createUser = createAsyncThunk(
  'createUser',
  async (request: Request, { rejectWithValue }) => {
    try {
      const response = await postUser(request);
      if (response.status !== 200) {
        return rejectWithValue("Couldn't create user");
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);
const userCreationSlice = createSlice({
  name: CONFIG_NAME,
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createUser.pending, state => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, state => {
        state.status = 'fulfilled';
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});
export const { resetStatus } = userCreationSlice.actions;
export default userCreationSlice.reducer;

const postUser = async (request: Request) => {
  return httpInstances.axiosGoInstance.post('/users', request.user, {
    headers: { 'x-gx-cap-token': request.captchaToken }
  });
};
