import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Paper } from '@mui/material';
import { Add, DeleteOutlined, SelectAll, FileDownload } from '@mui/icons-material';
import { RootState } from 'store';
import LaunchpadsDropdown from 'pages/Launchpad/ActionBar/LaunchpadsDropdown';
import { useAppDispatch } from 'hooks/redux-hooks';
import { clearLaunchpad } from 'slices/launchpadSlice';
import { setSymbols } from 'slices/symbolSelectorSlice';
import Dialog from 'components/Dialog';
import { SPOT_CATALOG_PATH } from 'utils/constants';

const LaunchpadActionBar = ({
  activeTab,
  setActiveTab
}: {
  activeTab: number;
  setActiveTab: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { launchpadSymbols } = useSelector((state: RootState) => state.launchpad);
  const [confirmClearDialogOpen, setConfirmClearDialogOpen] = useState(false);

  return (
    <>
      <Paper sx={{ mt: 3, px: 2, py: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          {/*<Button*/}
          {/*  disabled*/}
          {/*  variant='outlined'*/}
          {/*  sx={{ mr: 2 }}*/}
          {/*>*/}
          {/*  <BookmarkBorder />*/}
          {/*  Save*/}
          {/*</Button>*/}
          {/*<LaunchpadsDropdown />*/}
          <Button
            disabled={!launchpadSymbols.length}
            variant='outlined'
            // sx={{ ml: 2 }}
            onClick={() => setConfirmClearDialogOpen(true)}
          >
            <DeleteOutlined />
            Clear Data Hub
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          {[0].includes(activeTab) && (
            <Button
              disabled={!launchpadSymbols.length}
              onClick={() => dispatch(setSymbols(launchpadSymbols))}
              variant='outlined'
              sx={{ mr: 2 }}
            >
              <SelectAll />
              Select all data in Data Hub
            </Button>
          )}
          {[1, 2].includes(activeTab) && (
            <Button
              disabled={!launchpadSymbols.length}
              variant='outlined'
              sx={{ width: 165, mr: 2 }}
              onClick={() => setActiveTab(3)}
            >
              <FileDownload />
              Export data
            </Button>
          )}
          <Button
            variant='outlined'
            sx={{ width: 165 }}
            onClick={() => navigate('/catalog/' + SPOT_CATALOG_PATH)}
          >
            <Add />
            Select more
          </Button>
        </Box>
      </Paper>
      <Dialog
        text={
          <>
            Clear Data Hub?
            <br />
            <br />
            You will permanently lose these items from your Data Hub.
          </>
        }
        actionButtonText='Clear Data Hub'
        hasCancelButton
        open={confirmClearDialogOpen}
        handleClose={() => setConfirmClearDialogOpen(false)}
        handleAction={() => {
          dispatch(clearLaunchpad());
          setActiveTab(0);
          setConfirmClearDialogOpen(false);
        }}
      />
    </>
  );
};

export default LaunchpadActionBar;
