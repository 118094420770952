import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { Box, FormControl, Select, Typography } from '@mui/material';
import useSettingsChanger from './useSettingChanger';
import { Option } from 'slices/userConfigSlice';

const SettingChanger = ({
  label,
  userSetting,
  onChange,
  options
}: {
  label: string;
  userSetting: Option;
  onChange: (value: Option) => void;
  options: Option[];
}) => {
  const { selectedIndex, open, handleMenuItemClick, handleClose, handleOpen, selected } =
    useSettingsChanger({ options, onChange, userSetting });

  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        p: 3,
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: open ? 'block' : 'none',
          zIndex: 1,
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          left: 0,
          top: 0,
          background: 'rgba(18, 22, 63, 0.40)',
          backdropFilter: 'blur(1px)'
        }}
      ></Box>
      <Typography
        sx={{ width: '40%' }}
        variant='body2'
      >
        {label}
      </Typography>
      <FormControl>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={selected.label}
          onClose={handleClose}
          onOpen={handleOpen}
          disabled={options.length < 1}
          sx={theme => ({
            height: '30px',
            width: '250px',
            '.MuiSelect-select': {
              p: 0,
              '.MuiMenuItem-root': {
                p: 0,
                pl: 1
              }
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.text.primary}`
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.text.primary}`
            }
          })}
          renderValue={() => {
            return <MenuItem value={selected.value}>{selected.label}</MenuItem>;
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              sx={{ '&.MuiButtonBase-root': { pl: 1 } }}
              key={option.value + index}
              selected={index === selectedIndex}
              value={option.label}
              onClick={() => handleMenuItemClick(index)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SettingChanger;
