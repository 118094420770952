import Paper from '@mui/material/Paper';
import { Box, ButtonBase, Typography } from '@mui/material';
import { TERMS_VERSION } from 'components/TermsDialog/termsText';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';

const Terms = ({ termsSignedDate }: { termsSignedDate: string | null }) => {
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        p: 3,
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', width: '40%' }}>
        {' '}
        <Typography
          sx={{}}
          variant='body2'
        >
          Terms & Conditions
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 2, color: 'info.dark' }}
        >
          Version {TERMS_VERSION}
        </Typography>
      </Box>
      <Typography
        sx={{ fontSize: 16, width: '50%' }}
        variant='body2'
      >
        Signed{' '}
        <DateText
          dateString={termsSignedDate ?? ''}
          variant={DateTextVariants.WITH_TIME}
        />
      </Typography>
      <ButtonBase
        download={`terms_${TERMS_VERSION}.pdf`}
        target='_blank'
        href='/terms.pdf'
      >
        <Typography
          sx={{ color: 'primary.main', pl: '11px' }}
          variant='body2'
        >
          Download
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default Terms;
