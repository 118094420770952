import { ReactNode } from 'react';
import { Dialog, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    background: 'rgba(18, 22, 63, 0.40)',
    backdropFilter: 'blur(1px)'
  },
  '& .MuiDialog-paper': {
    background: 'background.default'
  }
}));

const SmallDialog = ({
  open,
  handleClose,
  children,
  hasCloseButton
}: {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
  hasCloseButton?: boolean;
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
    >
      {hasCloseButton && (
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'info.dark'
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </StyledDialog>
  );
};
export default SmallDialog;
