import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import httpInstances from 'api/axiosInstance';
import { LpDataItem } from 'pages/Launchpad/useLaunchpad';

export type LpData = LpDataItem[][];

type LaunchpadSelectionState = {
  lpData: LpData;
  launchpadSymbols: SelectedSymbol[];
  launchpadLimitReached: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: LaunchpadSelectionState = {
  lpData: [],
  launchpadSymbols: [],
  launchpadLimitReached: false,
  loading: false,
  error: false
};

export const fetchLpData = createAsyncThunk(
  'launchpad/fetchLpData',
  async (symbols: SelectedSymbol[], { rejectWithValue }) => {
    try {
      const requests = symbols.map(({ Code, Period, PeriodType, TimeRef }) =>
        Code
          ? httpInstances.axiosDataInstance.get(
              `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=all&metadata=true`
            )
          : null
      );
      const responses = await Promise.all(requests.filter(Boolean));
      return responses.map(response => response?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const launchpadSlice = createSlice({
  name: 'launchpad',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchLpData.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchLpData.fulfilled, (state, action: PayloadAction<LpData>) => {
        state.lpData = action.payload;
        state.loading = false;
      })
      .addCase(fetchLpData.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  },
  reducers: {
    addToLaunchpadSelection(state, action: PayloadAction<SelectedSymbol[]>) {
      const newSymbolsSet = new Set(action.payload.map(symbol => JSON.stringify(symbol)));

      posthog.capture('selector_add_to_data_hub_button_click', {
        symbols: action.payload
      });

      state.launchpadSymbols = [
        ...state.launchpadSymbols.filter(symbol => !newSymbolsSet.has(JSON.stringify(symbol))),
        ...action.payload
      ];

      state.launchpadLimitReached = state.launchpadSymbols.length > MAX_SELECTED_SYMBOLS;
    },
    setLaunchpadSymbols(state, action: PayloadAction<SelectedSymbol[]>) {
      state.launchpadSymbols = action.payload;
    },
    deleteSymbolFromLaunchpad(state, action: PayloadAction<string>) {
      const [timeRef, period, periodType, code] = action.payload.split('-');

      state.launchpadSymbols = state.launchpadSymbols.filter(
        symbol =>
          !(
            symbol.TimeRef === timeRef &&
            symbol.Period === period &&
            symbol.PeriodType === periodType &&
            symbol.Code === code
          )
      );

      state.lpData = state.lpData.filter(dataGroup =>
        dataGroup.every(
          item =>
            !(
              item.symbol.TimeRef === timeRef &&
              item.symbol.Period === period &&
              item.symbol.PeriodType === periodType &&
              item.symbol.Code === code
            )
        )
      );
    },
    setLpData(state, action: PayloadAction<LpData>) {
      state.lpData = action.payload;
    },
    clearLaunchpad(state) {
      state.launchpadSymbols = [];
      state.lpData = [];
      state.loading = false;
      state.error = false;
      state.launchpadLimitReached = false;
    },
    setLaunchpadLimitReached(state, action: PayloadAction<boolean>) {
      state.launchpadLimitReached = action.payload;
    }
  }
});

export const {
  addToLaunchpadSelection,
  setLaunchpadSymbols,
  clearLaunchpad,
  setLpData,
  setLaunchpadLimitReached,
  deleteSymbolFromLaunchpad
} = launchpadSlice.actions;

export default launchpadSlice.reducer;
