import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import authReducer from './slices/authSlice';
import symbolSelectorReducer from './slices/symbolSelectorSlice';
import launchpadReducer from './slices/launchpadSlice';
import insightsReducer from './slices/insightsSlice';
import userConfigReducer from './slices/userConfigSlice';
import userCreationReducer from './slices/userCreationSlice';
import listApiKeyReducer from './slices/apiKeyListSlice';
import insightsListReducer from './slices/insightsListSlice';
import catalogReducer from './slices/catalogSlice';

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});
export const store = configureStore({
  // process.env.NODE_ENV === 'development'
  devTools: import.meta.env.DEV,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([routerMiddleware]),
  reducer: combineReducers({
    auth: authReducer,
    symbolSelector: symbolSelectorReducer,
    launchpad: launchpadReducer,
    insights: insightsReducer,
    insightsList: insightsListReducer,
    userConfig: userConfigReducer,
    listApiKey: listApiKeyReducer,
    catalog: catalogReducer,
    userCreation: userCreationReducer,
    router: routerReducer
  })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
export const history = createReduxHistory(store);
