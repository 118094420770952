import { Box, Paper, Typography } from '@mui/material';
const PersonalDetails = ({
  firstName,
  lastName,
  companyName,
  email
}: {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  email: string | null;
}) => {
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 3,
        width: '50%'
      }}
    >
      <Typography
        variant='subtitle1'
        fontWeight={700}
        sx={{ pb: 1 }}
      >
        Personal Details
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant='body2'
          sx={{ color: 'info.dark', width: 150 }}
        >
          First Name
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 1, pb: 0.5 }}
        >
          {firstName}
        </Typography>{' '}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant='body2'
          sx={{ color: 'info.dark', width: 150 }}
        >
          Last Name
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 1, pb: 0.5 }}
        >
          {lastName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant='body2'
          sx={{ color: 'info.dark', width: 150 }}
        >
          Company Name
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 1, pb: 0.5 }}
        >
          {companyName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant='body2'
          sx={{ color: 'info.dark', width: 150 }}
        >
          Email Address
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 1, pb: 0.5 }}
        >
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
