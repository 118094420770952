import { ReactNode, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Link, Paper, Typography } from '@mui/material';
import { Cookie } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useAppDispatch } from 'hooks/redux-hooks';
import { logout } from 'slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { clearSelection } from 'slices/symbolSelectorSlice';

const CookieBanner = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['cookies-accepted']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(!cookies['cookies-accepted']);
  }, [cookies]);

  const handleDecline = () => {
    dispatch(clearSelection());
    dispatch(logout());
    navigate('/login');
  };

  const handleAccept = () => {
    var forever = new Date();
    forever.setDate(forever.getFullYear() + 2);
    setCookie('cookies-accepted', true, { expires: forever });
  };

  return (
    <>
      {open && (
        <Paper
          sx={theme => ({
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            mx: 2,
            px: 3,
            py: 7,
            zIndex: 3001,
            maxWidth: 2000,
            [theme.breakpoints.up('xl')]: {
              left: 'calc(50% - 1015px)'
            }
          })}
          elevation={3}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60 }}>
            <Cookie sx={{ color: 'primary.main', fontSize: 64 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 3 }}>
              <Typography sx={{ maxWidth: 640 }}>
                To ensure the best experience this application uses cookies as outlined in our{' '}
                <Link
                  underline='none'
                  rel='noopener'
                  target='_blank'
                  href='https://www.general-index.com/privacy-cookies'
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
              <Typography>Cookies are required for the app to work correctly.</Typography>
              <Typography>
                Declining cookies will disable essential features and you won’t be able to use the
                app.
              </Typography>
              <Typography>Do you wish to decline cookies?</Typography>
            </Box>

            <Button
              onClick={handleDecline}
              variant='outlined'
            >
              Decline
            </Button>
            <Button
              onClick={handleAccept}
              sx={{ ml: '20px' }}
              variant='contained'
            >
              <Cookie />
              Accept Cookies
            </Button>
          </Box>
        </Paper>
      )}
      {children}
    </>
  );
};
export default CookieBanner;
