import { Box, Button, Typography, Icon, styled } from '@mui/material';
import Draggable, { DraggableEvent } from 'react-draggable';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteOutlined, DragIndicator, Launch, ListAltOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import LimitDialog from './LimitDialog';
import { RootState } from 'store';
import { useAppDispatch } from 'hooks/redux-hooks';
import { clearSelection, dismissLimit } from 'slices/symbolSelectorSlice';
import { addToLaunchpadSelection, setLaunchpadLimitReached } from 'slices/launchpadSlice';
import CustomCheckbox from 'components/Checkbox';
import { MAX_SELECTED_SYMBOLS, SHOW_SELECTED_URL_PARAM, SPOT_CATALOG_PATH } from 'utils/constants';

const SelectorButton = styled(Button)(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 6,
  height: 24,
  marginRight: 8,
  backgroundColor: theme.palette.text.primary + '33',
  svg: { height: 18, width: 18, marginRight: 4 }
}));

const SymbolSelector = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checkIfInCatalog = () => location.pathname.toLowerCase().includes('/catalog');
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);
  const { launchpadSymbols, launchpadLimitReached } = useSelector(
    (state: RootState) => state.launchpad
  );

  const [position, setPosition] = useState<{ x: number; y: number }>();

  useEffect(() => {
    if (symbolSelectorState.symbols.length > 0) {
      if (!position) {
        setPosition(getDefaultPosition());
      }
    }
  }, [symbolSelectorState.symbols.length]);

  useEffect(() => {
    const handleResize = () => {
      setPosition(prevPosition => {
        if (prevPosition) {
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;
          const elementWidth = 564;
          const elementHeight = 50;

          let { x, y } = prevPosition;

          if (x + elementWidth > viewportWidth) x = viewportWidth - elementWidth;
          if (y + elementHeight > viewportHeight) y = viewportHeight - elementHeight;
          if (x < 0) x = 0;
          if (y < 0) y = 0;

          return { x, y };
        }
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [checkIfInCatalog]);

  const getDefaultPosition = () => {
    return {
      x: window.innerWidth / 2 - 296,
      y: checkIfInCatalog() ? window.innerHeight - 130 : 180
    };
  };

  const handleAddToLaunchpadClick = () => {
    if (symbolSelectorState.symbols.length + launchpadSymbols.length > MAX_SELECTED_SYMBOLS) {
      dispatch(setLaunchpadLimitReached(true));
    } else {
      dispatch(addToLaunchpadSelection(symbolSelectorState.symbols));
      dispatch(clearSelection());
      navigate('/data-hub');
    }
  };

  const handleClose = () => {
    dispatch(dismissLimit());
  };

  const handleDrag = (_: DraggableEvent, data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <>
      <LimitDialog
        handleClose={handleClose}
        open={symbolSelectorState.limitReached}
      />
      {position && (
        <Draggable
          handle='div.cursor'
          bounds='body'
          position={position}
          onDrag={handleDrag}
        >
          <Box
            sx={{
              p: 1.5,
              alignItems: 'center',
              justifyContent: 'space-around',
              height: 46,
              backgroundColor: 'primary.main',
              zIndex: 3030,
              position: 'absolute',
              display:
                symbolSelectorState.symbols.length > 0 &&
                !symbolSelectorState.limitReached &&
                !launchpadLimitReached
                  ? 'flex'
                  : 'none',
              flexDirection: 'row'
            }}
          >
            <Box
              className='cursor'
              style={{ paddingRight: 8 }}
            >
              <Icon sx={{ cursor: 'all-scroll' }}>
                <DragIndicator />
              </Icon>
            </Box>
            <Box
              sx={{
                cursor: 'default',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <CustomCheckbox
                sx={{ cursor: 'default' }}
                checked={true}
              />
              <Box
                sx={{
                  cursor: 'default',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  px: 1,
                  mr: 1
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  {symbolSelectorState.symbols.length} item
                  {symbolSelectorState.symbols.length > 1 && 's'} selected
                </Typography>
                <Typography sx={{ fontSize: 10 }}>
                  Max remaining:{' '}
                  {MAX_SELECTED_SYMBOLS -
                    symbolSelectorState.symbols.length -
                    launchpadSymbols.length}{' '}
                  items
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <SelectorButton
                onClick={() => {
                  dispatch(clearSelection());
                  setPosition(undefined);
                }}
              >
                <DeleteOutlined />
                <Typography variant='caption'>Clear Selection</Typography>
              </SelectorButton>
              <SelectorButton
                disabled={checkIfInCatalog()}
                onClick={() =>
                  navigate(`/catalog/${SPOT_CATALOG_PATH}?${SHOW_SELECTED_URL_PARAM}=true`)
                }
              >
                <ListAltOutlined />
                <Typography variant='caption'>View in Catalog</Typography>
              </SelectorButton>
              <SelectorButton onClick={handleAddToLaunchpadClick}>
                <Launch />
                <Typography variant='caption'>Add to Data Hub</Typography>
              </SelectorButton>
            </Box>
          </Box>
        </Draggable>
      )}
    </>
  );
};

export default SymbolSelector;
