export const TABLE_ROW_HEIGHT_PX = 24;
export const DEFAULT_CHART_HEIGHT = 450;
export const MAX_SELECTED_SYMBOLS = 20;
export const fullScreenChartPalette = [
  '#2D5BFF',
  '#7B61FF',
  '#FF6B2C',
  '#1AB394',
  '#9C27B0',
  '#D32F2F',
  '#996B21',
  '#20B15A',
  '#0597AE',
  '#E83A95'
];
export const stockChartPalette = [
  '#00BFFF',
  '#6363DF',
  '#F3733F',
  '#2E8B57',
  '#5284FF',
  '#E4597B',
  '#F8AE12',
  '#53F247',
  '#7FD4B6',
  '#FFD700'
];
export const GX_TOKEN_STORAGE_ATTRIBUTE_NAME = 'gxToken';
export const TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME = 'tripToken';
export const SHOW_SELECTED_URL_PARAM = 'showSelected';
export const SPOT_CATALOG_PATH = 'spot';
export const FWDCURVE_CATALOG_PATH = 'fwd';

export const METADATA_TERMS_FIELD = 'terms';
export const METADATA_FIRST_NAME_FIELD = 'hs_firstname';
export const METADATA_LAST_NAME_FIELD = 'hs_lastname';
export const METADATA_COMPANY_NAME_FIELD = 'hs_company_name';
