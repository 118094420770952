import Highcharts from 'highcharts/highstock';
import {
  styled,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  Box,
  ButtonBase
} from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(() => ({
  paddingRight: 30,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '22px',
  paddingRight: 8,
  paddingBottom: 8,
  verticalAlign: 'center',
  borderBottom: `0.5px solid ${theme.palette.info.light}`,
  '&:last-child tr': { border: 0, borderBottom: 0 }
}));

const LegendCheckBox = ({ series }: { series: Highcharts.Series }) => {
  const [checked, setChecked] = useState(true);
  const handleChange = () => {
    series.setVisible(!checked);
    setChecked(!checked);
  };
  return (
    <ButtonBase onClick={handleChange}>
      {checked ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
    </ButtonBase>
  );
};

const FullScreenLegend = ({ chart }: { chart: Highcharts.Chart }) => {
  const allChartSeries = chart?.series?.filter(
    (series: Highcharts.Series) => !series.name.includes('Navigator')
  );
  return (
    <Box sx={{ pt: 2, pr: 3 }}>
      <TableContainer
        sx={{ width: '100%', pl: '78px', maxHeight: 155, pr: 1 }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 600,
            minHeight: 150,
            thead: {
              position: 'sticky',
              top: 0,
              zIndex: 1303
            }
          }}
          size='small'
          aria-label='api keys table'
        >
          <TableHead>
            <TableRow sx={{ '.MuiTableCell-head': { backgroundColor: 'background.paper' } }}>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Title
                </Typography>
              </TableCell>
              <TableCell>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Code
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Time Ref
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Period Type
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Period
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Column
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: 150 }}>
            {allChartSeries?.map((series: any) => {
              const metadata = series.userOptions.metadata;
              return metadata ? (
                <StyledTableRow
                  key={series.name + metadata.field}
                  sx={{ '&:last-child': { borderBottom: 0 } }}
                >
                  <StyledTableCell
                    sx={{ width: '1%', pl: '2px', pr: 1 }}
                    component='th'
                    scope='row'
                  >
                    <LegendCheckBox series={series} />
                  </StyledTableCell>
                  <TableCell sx={{ width: '45%' }}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: 16,
                          height: 2,
                          flexShrink: 0,
                          marginTop: 8,
                          backgroundColor: series.color
                        }}
                      ></div>
                      <Typography
                        variant='body2'
                        sx={{ pl: 1 }}
                      >
                        {series.name}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    {' '}
                    <Typography variant='body2'>{metadata.Code}</Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{ width: '5%' }}
                  >
                    {' '}
                    <Typography variant='body2'>{metadata.TimeRef}</Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{ width: '10%' }}
                  >
                    {' '}
                    <Typography variant='body2'>{metadata.PeriodType}</Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{ width: '5%' }}
                  >
                    {' '}
                    <Typography variant='body2'>{metadata.Period}</Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{ width: '5%' }}
                  >
                    {' '}
                    <Typography variant='body2'>{metadata.field}</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FullScreenLegend;
