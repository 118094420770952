import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

const AppVersion = () => {
  const appVersion = import.meta.env.VITE_REACT_APP_VERSION;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <InfoOutlined sx={{ fontSize: 16, color: 'info.dark' }} />
      <Typography
        variant='body2'
        sx={{ pl: 1, color: 'info.dark' }}
      >
        GX Go app version {appVersion}
      </Typography>
    </Box>
  );
};

export default AppVersion;
