import { LpData } from 'pages/Launchpad/useLaunchpad';

export const mockChartSeries = [
  {
    settings: {
      type: 'line'
    },
    symbol: {
      key: {
        Code: 'GX0000266',
        Period: '1',
        PeriodType: 'Prompt',
        TimeRef: '1630',
        type: 'Last'
      },
      group: 'Prod_Indexes'
    }
  },
  {
    settings: {
      type: 'line'
    },
    symbol: {
      key: {
        Code: 'GX0000090',
        Period: '1',
        PeriodType: 'Prompt',
        TimeRef: '1630',
        type: 'Last'
      },
      group: 'Prod_Indexes'
    }
  },
  {
    settings: {
      type: 'line'
    },
    symbol: {
      key: {
        Code: 'GX0000087',
        Period: '1',
        PeriodType: 'Prompt',
        TimeRef: '1630',
        type: 'Last'
      },
      group: 'Prod_Indexes'
    }
  }
];

export const mockLaunchpadSymbols = [
  '{"Code":"GX0001083","PeriodType":"Month","Period":"1","TimeRef":"1600"}',
  '{"Code":"GX0000968","PeriodType":"Prompt","Period":"1","TimeRef":"1630"}',
  '{"Code":"GX0000977","PeriodType":"Prompt","Period":"1","TimeRef":"1630"}',
  '{"Code":"GX0000097","PeriodType":"Month","Period":"1","TimeRef":"1630"}'
];

export const mockLpData: LpData = [
  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  }
];
