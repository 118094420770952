import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { setLaunchpadSymbols, setLpData } from 'slices/launchpadSlice';
import { useAppDispatch } from 'hooks/redux-hooks';

const useLaunchpadStats = () => {
  const { lpData, launchpadSymbols } = useSelector((state: RootState) => state.launchpad);
  const dispatch = useAppDispatch();

  const uniqueColumns = new Set<string>();
  const uniqueCommodities = new Map<string, Set<string>>();
  const uniqueTradingHubs = new Map<string, Set<string>>();
  const uniquePricingBasis = new Map<string, Set<string>>();

  lpData.forEach(item => {
    const { symbol, metadata } = item[0] || {};

    const columnKey = `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}`;
    uniqueColumns.add(columnKey);

    if (!uniqueCommodities.has(metadata.Commodity)) {
      uniqueCommodities.set(metadata.Commodity, new Set());
    }
    uniqueCommodities.get(metadata.Commodity)!.add(columnKey);

    if (!uniqueTradingHubs.has(metadata.TradingHub)) {
      uniqueTradingHubs.set(metadata.TradingHub, new Set());
    }
    uniqueTradingHubs.get(metadata.TradingHub)!.add(columnKey);

    if (!uniquePricingBasis.has(metadata.PricingBasis)) {
      uniquePricingBasis.set(metadata.PricingBasis, new Set());
    }
    uniquePricingBasis.get(metadata.PricingBasis)!.add(columnKey);
  });

  const now = new Date();
  const updatedLast24Hours = lpData.filter(([firstItem]) => {
    const transactionDate = new Date(firstItem.transactionTime);
    return now.getTime() - transactionDate.getTime() <= 24 * 60 * 60 * 1000;
  }).length;

  const formatStatItems = (statItem: Map<string, Set<string>>) =>
    Array.from(statItem.entries())
      .sort((a, b) => b[1].size - a[1].size)
      .map(([key, value]) => ({ name: key, count: value.size }));

  const deleteGroupFromLaunchpad = (
    key: string,
    type: 'Commodity' | 'TradingHub' | 'PricingBasis'
  ) => {
    const filteredLpData = lpData.filter(([firstItem]) => {
      const matchesType =
        (type === 'Commodity' && firstItem.metadata.Commodity === key) ||
        (type === 'TradingHub' && firstItem.metadata.TradingHub === key) ||
        (type === 'PricingBasis' && firstItem.metadata.PricingBasis === key);

      return !matchesType;
    });

    const filteredLaunchpadSymbols = launchpadSymbols.filter(lpSym => {
      return !lpData.some(([firstItem]) => {
        const matchesType =
          (type === 'Commodity' && firstItem.metadata.Commodity === key) ||
          (type === 'TradingHub' && firstItem.metadata.TradingHub === key) ||
          (type === 'PricingBasis' && firstItem.metadata.PricingBasis === key);
        return (
          matchesType &&
          lpSym.Code === firstItem.symbol.Code &&
          lpSym.Period === firstItem.symbol.Period &&
          lpSym.PeriodType === firstItem.symbol.PeriodType &&
          lpSym.TimeRef === firstItem.symbol.TimeRef
        );
      });
    });

    dispatch(setLaunchpadSymbols(filteredLaunchpadSymbols));
    dispatch(setLpData(filteredLpData));
  };

  const stats = [
    { label: 'Commodity', values: formatStatItems(uniqueCommodities) },
    { label: 'Trading Hub', values: formatStatItems(uniqueTradingHubs) },
    { label: 'Pricing Basis', values: formatStatItems(uniquePricingBasis) }
  ];

  return {
    totalColumns: uniqueColumns.size,
    stats,
    updatedLast24Hours,
    deleteGroupFromLaunchpad
  };
};

export default useLaunchpadStats;
