import { Cell, flexRender } from '@tanstack/react-table';
import { Link, Palette } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { formatNumberWithPrecision, getDiffColor } from 'utils/commonFunctions';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import Tooltip from 'components/Tooltip';
import { CatalogItem } from 'slices/catalogSlice';
import CustomCheckbox from 'components/Checkbox';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

const CatalogTableCell = ({
  cell,
  isRestrictedData,
  isForwardCurves,
  palette
}: {
  cell: Cell<CatalogItem, unknown>;
  isRestrictedData: boolean;
  isForwardCurves: boolean;
  palette: Palette;
}) => {
  const { isSymbolSelected, toggleSymbolInSelection } = useSelectionFunctions();
  const columnId = cell.column.id;
  const cellValue = cell.getValue() as string;
  const returnCellColor = (isChangeColumn?: boolean, value?: string) => {
    if (isRestrictedData && !isForwardCurves) return palette.table.infoRowText;
    if (isChangeColumn) return getDiffColor(Number(value));
    return 'inherit';
  };
  const style = {
    display: 'flex',
    alignItems: 'center',
    width: cell.column.getSize(),
    padding: '1px 8px',
    fontSize: '14px',
    justifyContent: ['Last', 'Change'].includes(columnId) ? 'flex-end' : 'flex-start',
    color: returnCellColor(columnId === 'Change', cellValue)
  };

  const renderCellContent = () => {
    switch (columnId) {
      case 'Symbol':
        const symbol: unknown = cellValue;
        return (
          <CustomCheckbox
            disabled={isRestrictedData}
            checked={isSymbolSelected(symbol as SelectedSymbol)}
            onChange={() => {
              toggleSymbolInSelection(
                symbol as SelectedSymbol,
                isSymbolSelected(symbol as SelectedSymbol)
              );
            }}
          />
        );
      case 'Title':
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip
              variant='secondary'
              title={cellValue}
            >
              <span>{cellValue}</span>
            </Tooltip>
          </div>
        );

      case 'IndexModule':
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cellValue.split(',').join(', ')}
          </div>
        );
      case 'Index':
        return cellValue === 'Y' ? 'Index' : 'High, Mid, Low';
      case 'Factsheet':
        return (
          <Link
            href={`https://factsheets.g-x.co/${cell.getValue()}.pdf`}
            target='_blank'
            rel='noopener noreferrer'
            color='text.primary'
            sx={{
              textDecoration: 'none',
              fontSize: 15,
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            ❏
          </Link>
        );
      case 'Last':
      case 'Change':
        if (isRestrictedData) {
          return (
            <Tooltip
              title={
                <>
                  You don't have access to this.{' '}
                  <Link
                    href='https://www.general-index.com/contact-us'
                    target='_blank'
                    rel='noopener noreferrer'
                    color='text.primary'
                    sx={{ fontWeight: 700 }}
                  >
                    Request access
                  </Link>
                </>
              }
            >
              <LockOutlined
                sx={{ fontSize: '16px' }}
                color='inherit'
              />
            </Tooltip>
          );
        } else return <>{formatNumberWithPrecision(cellValue)}</>;
      case 'Periods':
        return cellValue?.toString().padStart(2, '0');
      case 'LastDate':
      case 'StartDate':
      case 'EndDate':
        return (
          <DateText
            dateString={cellValue}
            variant={DateTextVariants.FULL}
          />
        );
      default:
        return flexRender(cell.column.columnDef.cell, cell.getContext());
    }
  };

  return (
    <div
      key={cell.id + columnId}
      style={style}
    >
      {renderCellContent()}
    </div>
  );
};

export default CatalogTableCell;
