import useChartsBlock from './useChartsBlock';
import FullScrreenChartsBlock from './FullScreenChartsBlock';
import StockChartsBlock from './StockChartsBlock';
import { ChartsBlockTabType } from 'pages/Insights/InsightsComponents/types';
import './style.css';

const ChartsBlock = ({
  tabs,
  layout,
  marketTitle,
  pageTitle
}: {
  tabs: ChartsBlockTabType[];
  layout?: { fullWidth?: boolean; height?: number };
  marketTitle: string;
  pageTitle: string;
}) => {
  const { activeTab, setActiveTab, fullScreen, setFullScreen, loading, error, chartSeries } =
    useChartsBlock(tabs);

  if (fullScreen) {
    return (
      <FullScrreenChartsBlock
        loading={loading}
        error={error}
        chartSeries={chartSeries}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        marketTitle={marketTitle}
        pageTitle={pageTitle}
        tabs={tabs}
      />
    );
  }
  {
    return (
      <StockChartsBlock
        loading={loading}
        error={error}
        chartSeries={chartSeries}
        setFullScreen={setFullScreen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        layout={layout}
        marketTitle={marketTitle}
        pageTitle={pageTitle}
      />
    );
  }
};

export default ChartsBlock;
