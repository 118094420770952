import { forwardRef, useState } from 'react';
import { Box, Button, Checkbox, Dialog, Link, Slide, styled, Typography } from '@mui/material';
import { TransitionProps } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { TERMS_VERSION, TermsText } from './termsText';
import { acceptTerms, logout } from 'slices/authSlice';
import { useAppDispatch } from 'hooks/redux-hooks';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    backdropFilter: 'blur(1px)'
  },
  '& .MuiDialog-paper': {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: 'rgba(18, 22, 63, 0.4)'
  }
}));

const TermsDialog = ({ open }: { open: boolean }) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <StyledDialog
      fullScreen
      sx={{ zIndex: 3002 }}
      open={open}
      TransitionComponent={Transition}
    >
      <Box
        sx={theme => ({
          borderRadius: '15px',
          padding: 5,
          background: theme.palette.background.default
        })}
      >
        <Typography
          sx={{ mb: 2 }}
          variant='h5'
          fontSize={24}
          color='primary.main'
        >
          Terms and Conditions
        </Typography>
        <Typography
          variant='h5'
          fontWeight={600}
          fontSize={18}
        >
          PLATFORM AND DATA LICENCE CORE TERMS REFERENCE GX GO {TERMS_VERSION}
          <br />
        </Typography>
        <Typography
          sx={{ mb: 2 }}
          variant='h5'
          fontWeight={600}
          fontSize={18}
        >
          JANUARY 2025
        </Typography>
        <Box
          sx={{
            maxHeight: '60vh',
            maxWidth: 965,
            overflow: 'auto',
            mb: 1.5,
            '::-webkit-scrollbar': {
              width: '4px',
              height: '4px'
            },

            '::-webkit-scrollbar-thumb': {
              borderRadius: '12px',
              backgroundColor: 'rgb(204, 204, 204)',
              backgroundClip: 'content-box'
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <TermsText />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Checkbox
              sx={{
                width: 32,
                height: 32,
                '& .MuiSvgIcon-root': { fontSize: 32 }
              }}
              inputProps={{
                'aria-label': 'agree terms'
              }}
              size='large'
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            />
            <Typography sx={{ pl: 2, maxWidth: '50vw' }}>
              I confirm that I have read and accept the Terms and Conditions and{' '}
              <Link
                href={'https://www.general-index.com/privacy-cookies'}
                target='_blank'
                rel='noopener noreferrer'
                underline='hover'
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => {
                dispatch(logout());
                navigate('/login');
              }}
              sx={{
                mr: 4,
                color: 'text.primary'
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!termsAgreed}
              onClick={() => {
                dispatch(acceptTerms(new Date(Date.now()).toISOString()));
              }}
              size='large'
              sx={{
                width: '167px',
                borderRadius: '7.5px',
                backgroundColor: 'primary.main',
                color: 'text.primary'
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledDialog>
  );
};
export default TermsDialog;
