import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import StockChart from 'pages/Insights/InsightsComponents/ChartsBlock/StockChartsBlock/StockChart';
import FullScreenChart from 'pages/Insights/InsightsComponents/ChartsBlock/FullScreenChartsBlock/FullScreenChart';
import useChartSeries from 'pages/Insights/InsightsComponents/ChartsBlock/useChartSeries';
import PremiumFeatureScreen from 'pages/Launchpad/UpgradeToPremiumScreen';

const LaunchpadGraph = ({
  templateChartSeries,
  isSummary
}: {
  templateChartSeries: any;
  isSummary?: boolean;
}) => {
  const calculateChartHeight = (): number => {
    const VIEWPORT_HEIGHT = window.innerHeight;
    const EXTRA_PADDING = isSummary ? 540 : 330;
    return Math.floor((VIEWPORT_HEIGHT - EXTRA_PADDING) / TABLE_ROW_HEIGHT_PX);
  };
  const [chartHeight, setChartHeight] = useState(calculateChartHeight());

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(calculateChartHeight());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { chartSeries, error, loading } = useChartSeries(templateChartSeries);

  return (
    <Paper sx={{ p: 2, height: `calc(100vh - ${isSummary ? 454 : 235}px)`, position: 'relative' }}>
      <PremiumFeatureScreen />
      {isSummary ? (
        <StockChart
          error={error}
          loading={loading}
          chartSeries={chartSeries?.slice(0, 4)}
          height={chartHeight}
        />
      ) : (
        <FullScreenChart
          isLaunchpad
          error={error}
          loading={loading}
          chartSeries={chartSeries?.slice(0, 6)}
        />
      )}
    </Paper>
  );
};

export default LaunchpadGraph;
