import { Box, Button, Paper } from '@mui/material';
import { FileDownload, OpenInFull } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import LaunchpadPreview from '../Preview';
import LaunchpadGraph from '../Graph';
import LaunchpadStats from '../Stats';
import EmptyLaunchpadScreen from '../EmptyLaunchpadScreen';
import Tabs from 'components/Tabs';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import { RootState } from 'store';
import PremiumFeatureScreen from 'pages/Launchpad/UpgradeToPremiumScreen';

const LaunchpadSummary = ({
  chartSeries,
  setActiveTab,
  isLaunchpadEmpty
}: {
  chartSeries:
    | { settings: { type: 'line' }; symbol: { key: SelectedSymbol; group: string } }[]
    | undefined;
  setActiveTab: (index: number) => void;
  isLaunchpadEmpty: boolean;
}) => {
  const { loading } = useSelector((state: RootState) => state.launchpad);
  if (isLaunchpadEmpty) return <EmptyLaunchpadScreen />;

  return (
    <Box sx={{ display: 'flex', mt: 2 }}>
      <Box sx={{ width: 595, mr: 2 }}>
        <LaunchpadStats />
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <PremiumFeatureScreen noButton />
          <Tabs
            mainPageTitle='Data Hub'
            tabs={[{ name: 'Last four items added' }]}
            activeTab={0}
          />
          <Button
            disabled={loading}
            variant='outlined'
            sx={{ width: 165, mr: 2 }}
            onClick={() => setActiveTab(2)}
          >
            <OpenInFull />
            Full screen
          </Button>
        </Paper>
        <LaunchpadGraph
          isSummary
          templateChartSeries={chartSeries}
        />
      </Box>
      <Paper sx={{ flexGrow: 1, overflowX: 'auto', position: 'relative' }}>
        <PremiumFeatureScreen noButton />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            mainPageTitle='Data Hub'
            tabs={[{ name: 'All Data Hub items' }]}
            activeTab={0}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 165, mr: 2 }}
              onClick={() => setActiveTab(1)}
            >
              <OpenInFull />
              Full screen
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 165, mr: 2 }}
              onClick={() => setActiveTab(3)}
            >
              <FileDownload />
              Export data
            </Button>
          </Box>
        </Box>
        <LaunchpadPreview isSummary />
      </Paper>
    </Box>
  );
};

export default LaunchpadSummary;
