import { LpData } from '../useLaunchpad';
import useUserDetails from 'hooks/useUserAccess';
import { mockLpData } from 'pages/Launchpad/constants';

const useLaunchpadPreview = (lpData: LpData) => {
  const { checkUserHasPremium } = useUserDetails();
  const dataToShow = checkUserHasPremium() ? lpData : mockLpData;

  const groupedData: Record<
    string,
    {
      date: string;
      values: Record<string, { value: string; PeriodAbs: string; FactsheetVersion: string }>;
    }
  > = {};

  dataToShow.forEach(lpDataItem => {
    const date = lpDataItem.transactionTime.split('T')[0];
    const columnKey = `${lpDataItem.symbol.TimeRef}-${lpDataItem.symbol.Period}-${lpDataItem.symbol.PeriodType}-${lpDataItem.symbol.Code}-${lpDataItem.metadata.Title}`;

    if (!groupedData[date]) {
      groupedData[date] = { date, values: {} };
    }

    groupedData[date].values[columnKey] = {
      value: lpDataItem.values?.Index || lpDataItem.values?.Mid || '-',
      PeriodAbs: lpDataItem.values?.PeriodAbs || '-',
      FactsheetVersion: lpDataItem.values?.FactsheetVersion || '-'
    };
  });

  const rows = Object.values(groupedData).sort((a, b) => b.date.localeCompare(a.date));

  return { rows };
};

export default useLaunchpadPreview;
