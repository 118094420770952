import { Button, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useGenerationBody from './useGenerationBody';
import Loader from 'components/Loader';

const PostGenerationBody = ({ handleClose }: { handleClose: () => void }) => {
  const { listApiKeyState, toClipboard, exportToCsv } = useGenerationBody();

  return (
    <>
      {listApiKeyState.status === 'failed' ? (
        <>
          <DialogContent>
            <Typography>Error generating ApiKey</Typography>
            <Typography>Please contact the General Index support team.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 3, p: 0, minWidth: '40px' }}
              variant='text'
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : listApiKeyState.status === 'loading' ? (
        <Loader />
      ) : (
        <>
          <DialogContent sx={{ paddingBottom: 3 }}>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'info.dark'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              paddingBottom={'4px'}
              variant='subtitle2'
            >
              Your token has been created. You can now retrieve the API token.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ mr: 3 }}
              variant='outlined'
              onClick={exportToCsv}
            >
              Download .csv file
            </Button>
            <Button
              variant='outlined'
              onClick={toClipboard}
            >
              Copy key
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default PostGenerationBody;
