import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import SmallDialog from 'components/SmallDialog';
import { LockingDisableButton } from 'components/DisableableButton';
const ConfirmDialog = ({
  open,
  setOpen,
  deleteCallback
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deleteCallback: () => void;
}) => {
  return (
    <SmallDialog
      open={open}
      handleClose={() => setOpen(false)}
    >
      <>
        <DialogContent sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ mb: 3 }}>Delete API token?</Typography>
            <Typography
              variant='subtitle2'
              sx={{ mr: 3 }}
            >
              Are you sure you want to delete this API token?
              <br />
              This action cannot be undone, and any applications using this token will lose access.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            sx={{ mr: 2, minWidth: '40px' }}
            variant='text'
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <LockingDisableButton
            clickCallback={() => deleteCallback()}
            variant='contained'
          >
            Delete token
          </LockingDisableButton>
        </DialogActions>
      </>
    </SmallDialog>
  );
};
export default ConfirmDialog;
