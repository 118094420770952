import {
  CloudOutlined,
  CodeOutlined,
  DatasetOutlined,
  DataThresholdingOutlined,
  InsertDriveFileOutlined
} from '@mui/icons-material';
import CsvIcon from 'assets/images/exports/csv.svg';
import ExcelIcon from 'assets/images/exports/excel.svg';
import SnowflakeIcon from 'assets/images/exports/snowflake.svg';
import AwsIcon from 'assets/images/exports/aws.svg';
import GoogleCloudIcon from 'assets/images/exports/googleCloud.svg';
import NorthGravityIcon from 'assets/images/exports/northGravity.svg';
import AzureIcon from 'assets/images/exports/azure.svg';
import PythonIcon from 'assets/images/exports/python.svg';
import JsonIcon from 'assets/images/exports/json.svg';
import LsegIcon from 'assets/images/exports/lseg.svg';
import BloombergIcon from 'assets/images/exports/bloomberg.svg';
import IceIcon from 'assets/images/exports/ice.svg';
import EnverusIcon from 'assets/images/exports/enverus.svg';
import ZemaIcon from 'assets/images/exports/zema.svg';
import PowerBIIcon from 'assets/images/exports/powerBI.svg';
import FactsetIcon from 'assets/images/exports/factset.svg';
import VentriksIcon from 'assets/images/exports/ventriks.svg';
import GxApiIcon from 'assets/images/exports/gxAPI.svg';
import bloombergTerminalPreview from 'assets/images/exports/bloombergTerminalPreview.png';
import lsegWorkspacePreview from 'assets/images/exports/lsegWorkSpacePreview.png';
import enverusPreview from 'assets/images/exports/enverusPreview.png';
import excelSimplePreview from 'assets/images/exports/excelSimplePreview.png';
import excelAdvancedPreview from 'assets/images/exports/excelAdvancedPreview.png';

export const exportOptionsGroups = [
  {
    groupName: 'File exports',
    GroupIcon: InsertDriveFileOutlined,
    options: [
      {
        id: 'csv',
        label: 'CSV',
        icon: CsvIcon,
        actionHeading: 'CSV',
        outlined: true,
        disabled: false,
        previewOptions: [],
        previewImg: ''
      },
      {
        id: 'excel?type=simple',
        label: 'Simple Excel',
        icon: ExcelIcon,
        outlined: true,
        actionHeading: 'Simple Excel',
        previewOptions: [
          'Allows quick analysis and graphing',
          'No transaction time, just date',
          'Mid/index values only'
        ],
        previewImg: excelSimplePreview
      },
      {
        id: 'excel?type=advanced',
        label: 'Advanced Excel',
        icon: ExcelIcon,
        outlined: true,
        actionHeading: 'Advanced Excel',
        previewOptions: [
          'Complete data per series',
          'Full timing Including delivery & absolute period',
          'All columns returned'
        ],
        previewImg: excelAdvancedPreview
      }
    ]
  },
  {
    groupName: 'Cloud analytics platforms',
    GroupIcon: CloudOutlined,
    options: [
      {
        id: 'snowflake',
        icon: SnowflakeIcon,
        disabled: false,
        actionHeading: 'GX Data in Snowflake',
        previewOptions: [
          'Data immediately available: No ETL',
          'Tables with full metadata and history',
          'Available for all cloud providers in all regions'
        ],
        previewImg: ''
      },
      {
        id: 'aws',
        label: 'Amazon Web Services',
        icon: AwsIcon,
        disabled: false,
        actionHeading: 'GX Data in AWS',
        previewOptions: [
          'Data immediately available: No ETL',
          'Tables with full metadata and history',
          'Available as S3 share'
        ],
        previewImg: ''
      },
      {
        id: 'google_cloud',
        icon: GoogleCloudIcon,
        disabled: false,
        actionHeading: 'GX Data in Google Cloud',
        previewOptions: [
          'Data immediately available: No ETL',
          'Tables with full metadata',
          'Available via Google Cloud Storage'
        ],
        previewImg: ''
      },
      {
        id: 'azure',
        icon: AzureIcon,
        disabled: false,
        actionHeading: 'GX Data in Azure',
        previewOptions: [
          'Data immediately available: No ETL',
          'Tables with full metadata',
          'Blob storage via Azure Data Share'
        ],
        previewImg: ''
      },
      {
        id: 'north_gravity',
        icon: NorthGravityIcon,
        label: 'NorthGravity',
        disabled: false,
        actionHeading: 'GX Data in NorthGravity',
        previewOptions: [
          'Data immediately available: No ETL',
          'History, metadata, holiday calendars available',
          'Timeseries available in data preps and elsewhere'
        ],
        previewImg: ''
      }
    ]
  },
  {
    groupName: 'Business intelligence platforms',
    GroupIcon: DataThresholdingOutlined,
    options: [
      {
        id: 'power_bi',
        icon: PowerBIIcon,
        disabled: false,
        actionHeading: 'GX Data in Power BI',
        previewOptions: [
          'Data in Power BI dashboards.',
          'Live connection to refresh with latest data'
        ],
        previewImg: ''
      }
    ]
  },
  {
    groupName: 'Developer tools',
    GroupIcon: CodeOutlined,
    options: [
      {
        id: 'gx_api',
        icon: GxApiIcon,
        actionHeading: 'API',
        outlined: true,
        previewImg: '',
        previewOptions: [
          'All symbols for the chosen codes returned',
          'You need an API token to export your data.'
        ]
      },
      {
        id: 'python',
        icon: PythonIcon,
        label: 'Python',
        actionHeading: 'Python',
        outlined: true,
        previewOptions: [],
        previewImg: ''
      },
      {
        id: 'json',
        icon: JsonIcon,
        label: 'curl (json)',
        actionHeading: 'curl (json)',
        previewOptions: ['You need an API token to export your data.'],
        previewImg: '',
        disabled: false
      }
    ]
  },
  {
    groupName: 'Market data integrations',
    GroupIcon: DatasetOutlined,
    options: [
      {
        id: 'bloomberg_terminal',
        icon: BloombergIcon,
        label: 'Terminal',
        disabled: false,
        actionHeading: 'GX Data in Bloomberg Terminal',
        previewImg: bloombergTerminalPreview,
        previewOptions: [
          'Fully integrated data available with history at SPOT GX <GO>',
          'Ticker mappings available'
        ]
      },
      {
        id: 'bloomberg_bpipe',
        icon: BloombergIcon,
        label: 'BPIPE',
        disabled: false,
        actionHeading: 'GX Data in Bloomberg BPIPE',
        previewOptions: [
          'Fully integrated data available with history',
          'EID and Ticker mappings available'
        ]
      },
      {
        id: 'bloomberg_data_licence',
        icon: BloombergIcon,
        label: 'Data License',
        disabled: false,
        actionHeading: 'GX Data in Bloomberg Data Licence',
        previewOptions: [
          'Fully integrated data available with history',
          'EID and Ticker mappings available'
        ]
      },
      {
        id: 'lseg_workspace',
        icon: LsegIcon,
        label: 'Workspace',
        disabled: false,
        actionHeading: 'GX Data in LSEG Workspace',
        previewOptions: [
          'Fully integrated data available with history',
          'Prebuilt screens with key data',
          'RICS mappings available'
        ],
        previewImg: lsegWorkspacePreview
      },
      {
        id: 'lseg_data_scope',
        icon: LsegIcon,
        label: 'DataScope Select',
        disabled: false,
        actionHeading: 'GX Data in LSEG DataScope Select',
        previewOptions: ['Fully integrated data available with history', 'RICS mappings available']
      },
      {
        id: 'lseg_ldms',
        icon: LsegIcon,
        label: 'LDMS',
        disabled: false,
        actionHeading: 'GX Data in LSEG LDMS',
        previewOptions: ['Fully integrated data available with history', 'RICS mappings available']
      },
      {
        id: 'ice_connect',
        icon: IceIcon,
        label: 'Connect',
        disabled: false,
        actionHeading: 'GX Data in ICE Connect',
        previewOptions: [
          'Fully integrated data available with history',
          'Symbol and Ticker mappings available',
          'GX settled Swaps'
        ]
      },
      {
        id: 'ice_feed_products',
        icon: IceIcon,
        label: 'Feed Products',
        disabled: false,
        actionHeading: 'GX Data in ICE Feed Products',
        previewOptions: [
          'Fully integrated data available with history',
          'Daily and historical tickers available via Consolidated Feed'
        ]
      },
      {
        id: 'enverus',
        icon: EnverusIcon,
        disabled: false,
        actionHeading: 'GX Data in Enverus',
        previewOptions: [
          'Fully integrated data available with history',
          'Access GX data in Marketview, Sphere & Curvebuilder workflows'
        ],
        previewImg: enverusPreview
      },
      {
        id: 'zema',
        icon: ZemaIcon,
        disabled: false,
        actionHeading: 'GX Data in Zema',
        previewOptions: [
          'Fully integrated data available with history',
          'Single GX processor for all data access'
        ]
      },
      {
        id: 'fact_set',
        icon: FactsetIcon,
        disabled: false,
        actionHeading: 'GX Data in FactSet',
        previewOptions: ['Fully integrated data available with history']
      },
      {
        id: 'ventriks',
        icon: VentriksIcon,
        disabled: false,
        actionHeading: 'GX Data in Ventriks',
        previewOptions: [
          'Fully integrated data available with history',
          'Available via Data Marketplace or Data Platform'
        ]
      }
    ]
  }
];
