import { useState } from 'react';
import { Box } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import { DeleteOutlined } from '@mui/icons-material';
import PreviewTooltipContent from '../PreviewTooltipContent';
import Tooltip from 'components/Tooltip';
import { stockChartPalette } from 'utils/constants';
import { deleteSymbolFromLaunchpad } from 'slices/launchpadSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import Dialog from 'components/Dialog';

const ColumnHeader = ({
  columnKey,
  index,
  isSummary
}: {
  columnKey: string;
  index: number;
  isSummary?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [timeRef, period, periodType, code, title] = columnKey.split('-');

  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        onMouseEnter={() => setIsHovered(!isSummary)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Tooltip
          variant='secondary'
          title={<PreviewTooltipContent {...{ timeRef, period, periodType, code }} />}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              filter: isHovered ? 'brightness(20%)' : 'none',
              userSelect: 'none'
            }}
          >
            <LinesEllipsis
              text={title}
              maxLine='2'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </Box>
          {isHovered && !isSummary && (
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => setIsModalOpen(true)}
            >
              <DeleteOutlined />
            </Box>
          )}
        </Tooltip>
        <Box
          sx={{
            height: index < 4 && isSummary ? 4 : 2,
            mt: 0.5,
            width: '100%',
            backgroundColor: index < 4 && isSummary ? stockChartPalette[index] : 'text.primary'
          }}
        />
      </Box>
      <Dialog
        text={
          <>
            Remove item?
            <br />
            <br />
            This item will be removed from the Data Hub
          </>
        }
        actionButtonText='Remove item'
        hasCancelButton
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleAction={() => {
          dispatch(deleteSymbolFromLaunchpad(columnKey));
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default ColumnHeader;
