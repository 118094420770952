import { ReactNode, SyntheticEvent } from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import posthog from 'posthog-js';

interface Tab {
  name: string;
  disabled?: boolean;
  label?: ReactNode;
}

interface CustomTabsProps {
  mainPageTitle?: string | undefined;
  subPageTitle?: string | undefined;
  tabs: Tab[];
  activeTab: number;
  setActiveTab?: (index: number) => void;
  onChange?: () => void;
  height?: number;
  minTabWidth?: number;
  offset?: number;
  maxTabWidth?: number | 'unset';
}

const CustomTabs = ({
  mainPageTitle,
  subPageTitle,
  tabs,
  activeTab,
  setActiveTab,
  onChange,
  height = 48,
  minTabWidth = 120,
  maxTabWidth = 'unset',
  offset = 0,
  ...tabProps
}: CustomTabsProps) => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (onChange) onChange();
    if (setActiveTab) setActiveTab(newValue);
  };

  const handleTabClick = (
    mainPage: string | undefined,
    subPage: string | undefined,
    tabName: string
  ) => {
    posthog.capture('tab_click', {
      mainPage: mainPage,
      subPage: subPage,
      tabName: tabName
    });
  };

  return (
    <MuiTabs
      value={activeTab}
      onChange={handleChange}
      aria-label='tabs'
      variant='scrollable'
      scrollButtons='auto'
      sx={{ maxWidth: `calc(100% - ${offset}px)`, minHeight: `${height}px`, height: height }}
    >
      {tabs?.map(({ name, label, disabled }) => (
        <Tab
          onClick={() => {
            handleTabClick(mainPageTitle, subPageTitle, name);
          }}
          label={label || name}
          key={name}
          id={`tab-${name}`}
          aria-controls={`tabpanel-${name}`}
          disabled={disabled}
          sx={{
            textTransform: 'initial',
            height: height,
            minWidth: `${minTabWidth}px`,
            maxWidth: `${maxTabWidth}px`,
            minHeight: `${height}px`,
            p: 1,
            '&:not(:last-child)': { mr: '2px' }
          }}
          {...tabProps}
        />
      ))}
    </MuiTabs>
  );
};

export default CustomTabs;
