import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux-hooks';
import { logout } from 'slices/authSlice';
const Logout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logout());
  });
  return null;
};
export default Logout;
