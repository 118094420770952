import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLaunchpad from './useLaunchpad';
import LaunchpadSummary from './Summary';
import ActionBar from './ActionBar';
import LaunchpadPreview from './Preview';
import LaunchpadGraph from './Graph';
import LaunchpadExport from './Export';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import { RootState } from 'store';
import useUserDetails from 'hooks/useUserAccess';
import { mockChartSeries } from 'pages/Launchpad/constants';

const Launchpad = () => {
  const { activeTab, setActiveTab, chartSeries, isLaunchpadEmpty } = useLaunchpad();
  const { loading, lpData } = useSelector((state: RootState) => state.launchpad);
  const { checkUserHasPremium } = useUserDetails();

  const disabled = isLaunchpadEmpty || loading;

  const launchpadTabs = [
    {
      name: 'Summary',
      disabled
    },
    {
      name: 'Preview',
      disabled
    },
    {
      name: 'Chart',
      disabled
    },
    {
      name: 'Export',
      disabled
    }
  ];

  const chartSeriesToShow = checkUserHasPremium() ? chartSeries : mockChartSeries;

  const returnTabPanelContent = () => {
    switch (activeTab) {
      case 1:
        return <LaunchpadPreview />;
      case 2:
        return <LaunchpadGraph templateChartSeries={chartSeriesToShow} />;
      case 3:
        return <LaunchpadExport />;
      default:
        return (
          <LaunchpadSummary
            // @ts-expect-error type issue
            chartSeries={chartSeriesToShow}
            setActiveTab={setActiveTab}
            isLaunchpadEmpty={isLaunchpadEmpty}
          />
        );
    }
  };

  useEffect(() => {
    document.title = 'GX Go | Data Hub';
    return () => {
      document.title = 'GX Go';
    };
  }, []);

  useEffect(() => {
    if (!lpData.length) setActiveTab(0);
  }, [lpData]);

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end', width: 290 }}>
          <Typography
            variant='h2'
            sx={{ mr: 3 }}
            noWrap
          >
            Data Hub
          </Typography>
          {/*<Typography*/}
          {/*  noWrap*/}
          {/*  variant='h6'*/}
          {/*>*/}
          {/*  New Data Hub*/}
          {/*</Typography>*/}
        </Box>
        <Tabs
          mainPageTitle='Data Hub'
          tabs={launchpadTabs}
          minTabWidth={200}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </Box>
      <ActionBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {launchpadTabs.map(({ name }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          {returnTabPanelContent()}
        </TabPanel>
      ))}
    </MainLayout>
  );
};

export default Launchpad;
