import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { RootState } from 'store';
import { downloadAsCsv } from 'utils/commonFunctions';
import { createApiKey, resetGenerated } from 'slices/apiKeyListSlice';

const useGenerationBody = () => {
  const [apiKeyName, setApiKeyName] = useState('');
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const apiKeyListState = useSelector((state: RootState) => state.listApiKey);
  const loading = apiKeyListState.status === 'loading';
  const listApiKeyState = useSelector((state: RootState) => state.listApiKey);
  const generated = listApiKeyState.generated;
  const handleClose = (setOpen: (arg0: boolean) => void) => {
    dispatch(resetGenerated());
    setOpen(false);
  };

  const exportToCsv = () => {
    const header = 'Name,Key';
    const apiKey = listApiKeyState.apiKeyOutputs[listApiKeyState.apiKeyOutputs.length - 1];
    const name = apiKey?.label;
    const token = apiKey?.prefixedRawApiKey ?? '';
    const keyRow = name + ',' + token;
    const values = [header, keyRow];
    downloadAsCsv(values);
  };

  const toClipboard = () => {
    navigator.clipboard.writeText(
      listApiKeyState.apiKeyOutputs[listApiKeyState.apiKeyOutputs.length - 1]?.prefixedRawApiKey ??
        ''
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (error) setError('');
    setApiKeyName(event.target.value);
  };

  const onGenerate = () => {
    dispatch(createApiKey(apiKeyName)).then(response => {
      if (response.meta.requestStatus === 'rejected')
        setError(
          response.payload.message.includes('API key labels must be unique')
            ? 'Token with that name already exists'
            : response.payload.message
        );
    });
  };

  return {
    handleChange,
    apiKeyName,
    onGenerate,
    listApiKeyState,
    toClipboard,
    exportToCsv,
    handleClose,
    generated,
    error,
    loading
  };
};

export default useGenerationBody;
