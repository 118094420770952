import {
  Box,
  Button,
  ButtonBase,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThanksMessage } from '..';
import { EMAIL_PARAM } from '../useLogin';
import { useAppDispatch } from 'hooks/redux-hooks';
import GoLogo from 'components/GoLogo';
import { emailValidator } from 'utils/validators';
import { createUser, resetStatus, User } from 'slices/userCreationSlice';
import { RootState } from 'store';
import DefaultLayout from 'layouts/DefaultLayout';
import { getGxTokenFromStorage } from 'utils/commonFunctions';

const reCaptchaKey: string = import.meta.env.VITE_REACT_RE_CAPTCHA_KEY;

const markets = [
  'Oil',
  'Crude',
  'Refined Products',
  'LPG / NGLs',
  'Gasoline',
  'Biofuels & Transition Fuels',
  'Carbon',
  'Americas',
  'Europe & Africa',
  'Middle East & Asia'
];

const RegistrationForm = () => {
  const userCreationState = useSelector((state: RootState) => state.userCreation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = userCreationState.status === 'loading';
  const fontStyle = { mb: 2, fontSize: 24 };
  const [urlSearchParams] = useSearchParams();
  const userEmail = decodeURIComponent(urlSearchParams.get(EMAIL_PARAM) || '');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState('');

  const [market, setMarket] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>(userEmail);
  const [jobTitle, setJobTitle] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [jobTitleError, setJobTitleError] = useState<string>('');
  const [companyNameError, setCompanyNameError] = useState<string>('');
  const [marketError, setMarketError] = useState<string>('');

  useEffect(() => {
    dispatch(resetStatus());
    handleEntrance();
  }, []);

  const validateEmail = () => {
    const errorMessage = emailValidator(email);
    if (errorMessage) setEmailError(errorMessage);
    return !errorMessage;
  };

  const validateForm = () => {
    let isValid = true;
    if (firstName.length < 1) {
      setFirstNameError('Please provide first name');
      isValid = false;
    }
    if (lastName.length < 1) {
      setLastNameError('Please provide last name');
      isValid = false;
    }
    if (companyName.length < 1) {
      setCompanyNameError('Please provide company name');
      isValid = false;
    }
    if (jobTitle.length < 1) {
      setJobTitleError('Please provide job title');
      isValid = false;
    }
    if (!validateEmail()) {
      isValid = false;
    }
    if (market.length < 1) {
      setMarketError('Please provide market');
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const user: User = {
        email: email,
        hs_firstname: firstName,
        hs_lastname: lastName,
        hs_jobtitle: jobTitle,
        hs_company: companyName,
        prefMarket: market
      };
      dispatch(createUser({ user: user, captchaToken: token }));
      setRefreshReCaptcha(r => !r);
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof market>) => {
    const {
      target: { value }
    } = event;
    if (marketError) setMarketError('');
    setMarket(value);
  };

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const handleEntrance = () => {
    const gxToken = getGxTokenFromStorage();
    if (gxToken) navigate('/markets');
  };
  return (
    <DefaultLayout>
      <Box sx={{ mt: '8%' }}>
        {userCreationState.status !== 'failed' && userCreationState.status !== 'fulfilled' && (
          <ButtonBase
            onClick={() => {
              navigate('/login');
            }}
            sx={{ mb: 3 }}
          >
            <ChevronLeft fontSize='large' />
            <Typography
              variant='subtitle1'
              sx={{ fontSize: 24 }}
            >
              Back to Login
            </Typography>
          </ButtonBase>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 'fit-content',
            ml: 1.5
          }}
        >
          <GoLogo open={true} />
          {userCreationState.status === 'failed' && (
            <Box sx={{ maxWidth: '550px', mt: 3 }}>
              <ThanksMessage />
            </Box>
          )}
          {userCreationState.status === 'fulfilled' && (
            <Box sx={{ maxWidth: '550px', mt: 3 }}>
              <Box>
                <Typography sx={fontStyle}>Please check your inbox! </Typography>
                <Typography sx={fontStyle}>We’ll be in touch shortly!</Typography>
              </Box>
            </Box>
          )}
          {userCreationState.status !== 'failed' && userCreationState.status !== 'fulfilled' && (
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <Box sx={{ maxWidth: 740, mt: 3 }}>
                <GoogleReCaptcha
                  onVerify={onVerify}
                  action='registrationForm'
                  refreshReCaptcha={refreshReCaptcha}
                />

                <Typography sx={{ mb: 2, fontSize: 24 }}>
                  To register please complete your details below
                </Typography>
                <Box
                  minHeight={84}
                  width={740}
                >
                  <TextField
                    variant='filled'
                    required
                    autoComplete='text'
                    id='firstName'
                    label='First Name'
                    name='firstName'
                    sx={{ width: 360 }}
                    value={firstName}
                    onChange={e => {
                      if (firstNameError) setFirstNameError('');
                      setFirstName(e.target.value);
                    }}
                    error={!!firstNameError}
                    helperText={firstNameError}
                    disabled={loading}
                  />
                  <TextField
                    variant='filled'
                    required
                    autoComplete='text'
                    id='lastName'
                    label='Last Name'
                    name='lastName'
                    sx={{ width: 360, ml: 2 }}
                    value={lastName}
                    onChange={e => {
                      if (lastNameError) setLastNameError('');
                      setLastName(e.target.value);
                    }}
                    error={!!lastNameError}
                    helperText={lastNameError}
                    disabled={loading}
                  />
                  <TextField
                    variant='filled'
                    required
                    type='email'
                    autoComplete='email'
                    id='email'
                    label='Email Adress'
                    name='email'
                    sx={{ width: 360, mt: 3 }}
                    value={email}
                    onChange={e => {
                      if (emailError) setEmailError('');
                      setEmail(e.target.value);
                    }}
                    error={!!emailError}
                    helperText={emailError}
                    disabled={loading}
                  />
                  <TextField
                    variant='filled'
                    required
                    autoComplete='companyName'
                    id='companyName'
                    label='Company Name'
                    name='companyName'
                    sx={{ width: 360, ml: 2, mt: 3 }}
                    value={companyName}
                    onChange={e => {
                      if (companyNameError) setCompanyNameError('');
                      setCompanyName(e.target.value);
                    }}
                    error={!!companyNameError}
                    helperText={companyNameError}
                    disabled={loading}
                  />
                  <TextField
                    variant='filled'
                    required
                    id='jobTitle'
                    label='Job Title'
                    name='jobTitle'
                    sx={{ width: 360, mt: 3 }}
                    value={jobTitle}
                    onChange={e => {
                      if (jobTitleError) setJobTitleError('');
                      setJobTitle(e.target.value);
                    }}
                    error={!!jobTitleError}
                    helperText={jobTitleError}
                    disabled={loading}
                  />
                  <FormControl
                    variant='filled'
                    sx={{ width: 360, ml: 2, mt: 3, 'div.MuiInputBase-root': { height: 53 } }}
                  >
                    <InputLabel
                      sx={{ color: marketError ? 'red' : 'text.secondary' }}
                      id='market-select-label'
                    >
                      Select Market *
                    </InputLabel>
                    <Select
                      disabled={loading}
                      required
                      labelId='market-select-label'
                      id='market-select'
                      value={market}
                      onChange={handleChange}
                      error={!!marketError}
                      input={<FilledInput />}
                    >
                      {markets.map(name => (
                        <MenuItem
                          key={name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText sx={{ color: 'red' }}>{marketError}</FormHelperText>
                  </FormControl>
                </Box>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={loading || !token.length}
                  sx={{
                    mt: 1,
                    mb: 2,
                    color: 'background.default',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loading ? 'Submiting...' : 'Submit'}
                </Button>
              </Box>
            </GoogleReCaptchaProvider>
          )}
        </Box>
      </Box>
    </DefaultLayout>
  );
};
export default RegistrationForm;
