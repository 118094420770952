import { Box, Paper, Typography } from '@mui/material';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';

const AccountDetails = ({ accessLevel }: { accessLevel: string | null }) => {
  const { checkUserIsFree } = useUserDetails();

  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        width: '50%'
      }}
    >
      <Typography
        variant='subtitle1'
        fontWeight={700}
        sx={{ pb: 1 }}
      >
        Account Details
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant='body2'
          sx={{ color: 'info.dark', width: 150 }}
        >
          Access Level
        </Typography>
        <Typography
          variant='body2'
          sx={{ pl: 1 }}
        >
          {accessLevel}
        </Typography>
      </Box>
      {checkUserIsFree() && <UpgradeToPremiumButton sx={{ mt: 3 }} />}
    </Box>
  );
};

export default AccountDetails;
