import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import httpInstances from '../api/axiosInstance';
import { RootState } from '../store';
import {
  clearStoragesFromTokensAndUserInfo,
  retrieveFromUserMetadata,
  setTripTokenToStorage
} from 'utils/commonFunctions';
import { METADATA_TERMS_FIELD } from 'utils/constants';
export enum UserAccess {
  None = 'None',
  Internal = 'Internal',
  Premium = 'Premium',
  Free = 'Free'
}
export type UserMetadata = {
  metadataKey: string;
  metadataValue: string;
};

type AuthApiState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled' | 'notFound';
  error: string | null;
  userEmail: string | null;
  tripToken: string | null;
  userAccess: UserAccess | null;
  metadata: UserMetadata[] | null;
  terms: string | null;
  hsObjectId: string | null;
};

type ErrorResponse = {
  message: string;
};

const initialState: AuthApiState = {
  status: 'idle',
  error: null,
  userEmail: null,
  tripToken: null,
  userAccess: null,
  hsObjectId: null,
  terms: null,
  metadata: null
};

export const loginGX = createAsyncThunk('loginGX', async (email: string, { rejectWithValue }) => {
  try {
    await httpInstances.axiosGoInstance.post('/login', `{"email": "${email}"}`);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue({ data: error.response.data, status: error.response.status });
    }
    throw error;
  }
});

export const loginTrip = createAsyncThunk(
  'loginTrip',

  async (gxToken: string, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosGoInstance.get('/token', {
        params: { token: gxToken }
      });
      setTripTokenToStorage(data.token);
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    acceptTerms(state, action: PayloadAction<string>) {
      const terms = action.payload;
      state.terms = terms;
      saveTerms(terms, state.userEmail ?? '');
    },
    logout(state) {
      clearStoragesFromTokensAndUserInfo().then(() => {
        state = initialState;
      });
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginGX.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginGX.fulfilled, state => {
        state.status = 'fulfilled';
      })
      .addCase(loginGX.rejected, (state, action) => {
        const response = action.payload as { data: string; status: number };
        if (response?.status === 404) {
          state.status = 'notFound';
        } else {
          state.status = 'failed';
          state.error =
            (action.payload as ErrorResponse).message || action.error.message || 'Login failed';
        }
      })
      .addCase(loginTrip.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginTrip.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.userEmail = action.payload?.userEmail;
        state.tripToken = action.payload?.token;
        state.userAccess = UserAccess[action.payload?.access as UserAccess];
        state.hsObjectId = action.payload?.hsObjectId;
        state.metadata = action.payload?.metadata;
        state.terms = retrieveFromUserMetadata(action.payload?.metadata, METADATA_TERMS_FIELD);
      })
      .addCase(loginTrip.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

const saveTerms = async (terms: string, email: string) => {
  return httpInstances.axiosGoInstance.patch('/users/' + email, { terms });
};

export const { resetStatus, logout, acceptTerms } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export const selectMemoizedAuthState = createSelector(selectAuthState, auth => ({
  status: auth.status,
  userEmail: auth.userEmail
}));

export default authSlice.reducer;
