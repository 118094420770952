import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TokenGenerationComponent from './TokenGeneration';
import SettingChanger from './SettingChanger';
import useProfile from './useProfile';
import PersonalDetails from './PersonalDetails';
import AccountDetails from './AccountDetails';
import AppVersion from './AppVersion';
import Terms from './Terms';
import MainLayout from 'layouts/MainLayout';
import { RootState } from 'store';
import Loader from 'components/Loader';
import { retrieveFromUserMetadata } from 'utils/commonFunctions';
import {
  METADATA_COMPANY_NAME_FIELD,
  METADATA_FIRST_NAME_FIELD,
  METADATA_LAST_NAME_FIELD
} from 'utils/constants';

const Profile = () => {
  const userConfigState = useSelector((state: RootState) => state.userConfig);
  const authState = useSelector((state: RootState) => state.auth);

  const {
    timezone,
    changeUserSetting,
    dateFormat,
    timezoneOptions,
    dateFormatOptions,
    insightsOptions,
    getDefaultInsight
  } = useProfile();

  useEffect(() => {
    document.title = 'GX Go | Settings';
    return () => {
      document.title = 'GX Go';
    };
  });

  return (
    <MainLayout>
      <Typography
        variant='h2'
        sx={{ mt: 3 }}
      >
        Settings
      </Typography>
      {userConfigState.status === 'loading' ? (
        <Loader />
      ) : (
        <Stack
          sx={{ width: '100%', mt: 3 }}
          spacing={{ xs: 2, sm: 3 }}
          direction='row'
          useFlexGap
          flexWrap='wrap'
        >
          <Stack
            sx={{ width: '100%' }}
            spacing={3}
            direction='row'
          >
            <PersonalDetails
              firstName={retrieveFromUserMetadata(
                authState.metadata || [],
                METADATA_FIRST_NAME_FIELD
              )}
              lastName={retrieveFromUserMetadata(
                authState.metadata || [],
                METADATA_LAST_NAME_FIELD
              )}
              companyName={retrieveFromUserMetadata(
                authState.metadata || [],
                METADATA_COMPANY_NAME_FIELD
              )}
              email={authState.userEmail}
            />
            <AccountDetails accessLevel={authState.userAccess} />
          </Stack>
          <SettingChanger
            label='Timezone'
            userSetting={timezone}
            onChange={userSetting => changeUserSetting('timezone', userSetting)}
            options={timezoneOptions}
          />
          <SettingChanger
            label='Date Format'
            userSetting={dateFormat}
            onChange={userSetting => changeUserSetting('dateFormat', userSetting)}
            options={dateFormatOptions}
          />
          <SettingChanger
            label='Default Market'
            userSetting={getDefaultInsight()}
            onChange={userSetting => changeUserSetting('defaultInsight', userSetting)}
            options={insightsOptions}
          />
          <TokenGenerationComponent />
          <Terms termsSignedDate={authState.terms} />
          <AppVersion />
        </Stack>
      )}
    </MainLayout>
  );
};

export default Profile;
