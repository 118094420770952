import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000
    }
  },
  palette: {
    mode,
    error: {
      main: '#FF1744'
    },
    success: {
      main: '#00E676'
    },
    info: {
      main: '#778899',
      dark: '#6C737A',
      light: '#4F5458'
    },
    primary: {
      ...(mode === 'dark' && {
        main: '#E84E0F',
        light: '#FF7043'
      })
    },
    secondary: {
      ...(mode === 'dark' && {
        main: '#121836'
      })
    },
    ...(mode === 'dark' && {
      background: {
        default: '#070818',
        paper: '#0D1228',
        additionalPaper: '#12163F'
      }
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: '#E84E0F'
          }
        : {
            primary: '#F8F8F8',
            secondary: '#A1A6AB'
          })
    },
    table: {
      forwardCurve: '#001F18',
      spot: '#10035D',
      infoRowBg: '#2E3340',
      infoRowText: '#cccccc',
      highlight: '#31475A'
    }
  },
  typography: {
    fontFamily: 'Cairo, Arial, sans-serif',
    color: '#A1A6AB',
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h4: {
      fontSize: '2rem'
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: '700'
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Cairo, Arial, sans-serif'
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        text: {
          borderRadius: '16px/22px',
          fontSize: '16px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: '1.5',
          fontFamily: 'Cairo, Arial, sans-serif',
          textTransform: 'initial',
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                backgroundColor: '#E84E0F',
                '&:hover': {
                  backgroundColor: '#FF7043'
                }
              }
            },
            {
              props: { variant: 'text' },
              style: {
                color: '#F8F8F8'
              }
            },
            {
              props: { variant: 'outlined' },
              style: {
                color: '#F8F8F8',
                borderColor: '#F8F8F8',
                '&:hover': {
                  backgroundColor: '#9993912b'
                }
              }
            }
          ],
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            marginRight: '8px'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Cairo, Arial, sans-serif',
          fontSize: '14px',
          px: 0.2,
          '&.Mui-disabled': {
            opacity: 0.5
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundImage: 'none!important' // Remove MUI's background-image globally
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          backgroundImage: 'none!important', // Remove MUI's background-image globally
          fontFamily: 'Cairo, Arial, sans-serif',
          placeholder: {
            color: '#6C737A'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          padding: '2px 0'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: 'none'
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
          },
          '&:after': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: '#121836',
          textTransform: 'capitalize',
          color: '#A1A6AB',
          '&.Mui-selected': {
            color: '#F8F8F8'
          },
          '&:hover': {
            color: '#E84E0F'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: '14px',
          height: '14px',
          padding: 0
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '14px',
          height: '14px',
          padding: '0px'
        },
        colorPrimary: {
          '& .MuiSvgIcon-root': {
            width: '18px',
            height: '18px'
          },
          '&.Mui-checked': {
            color: '#f8f8f8'
          }
        }
      },
      defaultProps: {
        size: 'small'
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          gap: '8px'
        },
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          position: 'relative',
          borderRight: '1px solid #121836'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none'
          },
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0
          },
          boxShadow: 'none'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '36px',
          '&.Mui-expanded': {
            minHeight: '36px',
            marginTop: 0
          }
        },
        content: {
          margin: '8px 0',
          '&.Mui-expanded': {
            margin: '8px 0'
          }
        }
      }
    }
  }
});

// @ts-expect-error mui-config
export const darkModeTheme = createTheme(getDesignTokens('dark'));
