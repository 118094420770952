import React from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { history, store } from './store';
import AppRoutes from './AppRoutes';
import { darkModeTheme } from 'utils/mui';
import { TrackingLister } from 'components/Tracking';
import './App.css';
import SymbolSelector from 'components/SymbolSelector';
import CookieBanner from 'components/CookieBanner';
const App: React.FC = () => {
  return (
    <ThemeProvider theme={darkModeTheme}>
      <ReduxStoreProvider store={store}>
        <CssBaseline />
        <HistoryRouter history={history}>
          <SymbolSelector />
          <TrackingLister>
            <CookieBanner>
              <AppRoutes />
            </CookieBanner>
          </TrackingLister>
        </HistoryRouter>
      </ReduxStoreProvider>
    </ThemeProvider>
  );
};

export default App;
