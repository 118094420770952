import { Box, Button, Link, Typography } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import useCodeSnippetPreview from './useCodeSnippetPreview';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';

const CodeSnippetPreviewScreen = ({
  exportType,
  from,
  to,
  period,
  periodNumber,
  includeMetadata,
  capturePosthogEvent
}: {
  exportType: 'all' | 'range' | 'dates';
  from?: Date | null;
  to?: Date | null;
  period?: string;
  periodNumber?: string;
  includeMetadata: boolean;
  capturePosthogEvent: () => void;
}) => {
  const { copyToClipboard, loading, error, fileContent } = useCodeSnippetPreview(
    exportType,
    includeMetadata,
    from,
    to,
    period,
    periodNumber
  );

  const { checkUserHasPremium } = useUserDetails();

  const docsLink = (
    <Typography sx={{ p: 2, fontSize: '12px' }}>
      For more information on the GX SDK, please visit our{' '}
      <Link
        href={`https://docs.g-x.co/#python-sdk`}
        target='_blank'
        rel='noopener noreferrer'
        underline='hover'
      >
        documentation site
      </Link>
      .
    </Typography>
  );

  if (!checkUserHasPremium())
    return (
      <>
        <UpgradeToPremiumButton sx={{ ml: 2 }} />
        {docsLink}
      </>
    );

  return (
    <Box sx={{ minWidth: 470, maxWidth: 735 }}>
      <Box sx={{ ml: 2, display: 'flex' }}>
        {
          <Button
            variant='contained'
            onClick={() => {
              copyToClipboard();
              capturePosthogEvent();
            }}
            disabled={!fileContent || loading}
            sx={{ mr: 2 }}
          >
            Copy to clipboard
          </Button>
        }
      </Box>
      {error && (
        <Typography
          color='error'
          sx={{ p: 2, fontSize: '14px' }}
        >
          An error occurred while fetching data. Please try again.
        </Typography>
      )}
      {loading ? (
        <Typography sx={{ p: 2, fontSize: '14px' }}>
          Retrieving your data, please wait...
        </Typography>
      ) : (
        <Box
          sx={{
            whiteSpace: 'pre',
            fontFamily: 'monospace',
            fontSize: '14px',
            mt: 3
          }}
        >
          {docsLink}
          <SyntaxHighlighter
            language='python'
            style={nightOwl}
            customStyle={{ backgroundColor: '#1A224D', margin: 0, height: 487 }}
          >
            {fileContent}
          </SyntaxHighlighter>
        </Box>
      )}
    </Box>
  );
};

export default CodeSnippetPreviewScreen;
