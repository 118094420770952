import { Box, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCatalog from './useCatalog';
import CatalogTable from './CatalogTable';
import CatalogSkeleton from './CatalogSkeleton';
import { getAmountLabel } from './constants';
import { useAppSelector } from 'hooks/redux-hooks';
import MainLayout from 'layouts/MainLayout';
import { selectCatalogStatus } from 'slices/catalogSlice';
import Tabs from 'components/Tabs';
import { FWDCURVE_CATALOG_PATH, SPOT_CATALOG_PATH } from 'utils/constants';

const Catalog = () => {
  const status = useAppSelector(selectCatalogStatus);
  const {
    filterOpen,
    setFilterOpen,
    filteredData,
    table,
    activeTab,
    isForwardCurves,
    showDiscontinued,
    setShowDiscontinued,
    expandedData,
    setExpandedData,
    specialFilters,
    showSelectedSymbols,
    setUrlSearchParams
  } = useCatalog();

  const catalogTabs = [
    {
      name: 'Spot',
      label: 'Spot'
    },
    {
      name: 'Forward curves',
      label: 'Forward Curves'
    }
  ];

  useEffect(() => {
    document.title = 'GX Go | Catalog';
    return () => {
      document.title = 'GX Go';
    };
  });
  const navigate = useNavigate();
  return (
    <MainLayout>
      {status !== 'loading' && status !== 'idle' ? (
        <>
          <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end', width: 390 }}>
              <Typography
                variant='h2'
                sx={{ mr: 3 }}
                noWrap
              >
                Catalog
              </Typography>
              <Typography
                noWrap
                variant='h6'
              >
                {getAmountLabel(table.getFilteredRowModel().rows, isForwardCurves, expandedData)}
              </Typography>
            </Box>
            <Tabs
              mainPageTitle='CatalogTable'
              tabs={catalogTabs}
              minTabWidth={300}
              activeTab={activeTab}
              setActiveTab={() => {}}
              onChange={() => {
                setUrlSearchParams({});
                navigate(`/catalog/${isForwardCurves ? SPOT_CATALOG_PATH : FWDCURVE_CATALOG_PATH}`);
              }}
            />
          </Box>
          <Paper sx={{ mt: 3 }}>
            <CatalogTable
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              filteredData={filteredData}
              table={table}
              isForwardCurves={isForwardCurves}
              expandedData={expandedData}
              setExpandedData={setExpandedData}
              showDiscontinued={showDiscontinued}
              setShowDiscontinued={setShowDiscontinued}
              specialFilters={specialFilters}
              showSelectedSymbols={showSelectedSymbols}
            />
          </Paper>
        </>
      ) : (
        <CatalogSkeleton />
      )}
    </MainLayout>
  );
};

export default Catalog;
