import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  styled,
  TextField,
  Typography
} from '@mui/material';
import useGenerationBody from './useGenerationBody';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: theme.palette.text.primary
  },
  '& label.Mui-error': { color: theme.palette.text.primary },
  '& div.MuiInput-underline::after': {
    borderBottom: `2px solid ${theme.palette.text.primary}`
  }
}));

const PreGenerationBody = ({ handleClose }: { handleClose: () => void }) => {
  const { handleChange, apiKeyName, onGenerate, error, loading } = useGenerationBody();
  return (
    <>
      <DialogContent sx={{ paddingBottom: 2 }}>
        <Box sx={{ paddingTop: 2, display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant='subtitle2'
            sx={{ mr: 3 }}
          >
            Name
          </Typography>
          <StyledTextField
            id='key-label'
            label='Type name here...'
            variant='standard'
            value={apiKeyName}
            onChange={handleChange}
            autoComplete='off'
            autoFocus
            error={!!error}
            helperText={error}
            disabled={loading}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          sx={{ mr: 2, minWidth: '40px' }}
          variant='text'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          disabled={!apiKeyName.length || loading || Boolean(error.length)}
          onClick={() => onGenerate()}
        >
          Generate
        </Button>
      </DialogActions>
    </>
  );
};

export default PreGenerationBody;
