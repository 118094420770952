import { useSearchParams } from 'react-router-dom';
import { Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { CatalogItem } from 'slices/catalogSlice';
import {
  CountedLabelFunction,
  extractExistingParams,
  labeledFilterColumns,
  SpecialFiltersType
} from 'pages/Catalog/constants';

type SpecialFilterOption = {
  label: string;
  key: keyof SpecialFiltersType;
  visible: boolean;
};

const useTableFilters = (table: Table<CatalogItem>, specialFilters: SpecialFiltersType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});

  function countOccurence(counts: { [label: string]: number }, label: string) {
    if (counts[label]) {
      counts[label]++;
    } else {
      counts[label] = 1;
    }
  }

  useEffect(() => {
    const extracted: any = extractExistingParams(searchParams);
    let filters: { [key: string]: string[] } = {};
    labeledFilterColumns.forEach(filter => {
      const key = Object.keys(filter)[0];
      if (extracted[key]) {
        filters = { ...filters, [key]: extracted[key] };
      } else {
        filters = { ...filters, [key]: [] };
      }
    });

    Object.entries(filters).forEach(filter => {
      table.getColumn(filter[0])?.setFilterValue(filter[1]);
    });
    setFilters(filters);
  }, [searchParams]);

  const getCountedLabels: CountedLabelFunction = (
    key: string,
    joined?: boolean
  ): { label: string; count: number }[] => {
    const counts: { [label: string]: number } = {};
    table.getFilteredRowModel().rows.forEach(row => {
      if (joined) {
        const label: string = row.getValue(key);
        const labels: string[] = label.split(',');
        labels.forEach(label => {
          countOccurence(counts, label);
        });
      } else {
        const label: string = row.getValue(key);
        countOccurence(counts, label);
      }
    });

    return Object.entries(counts)
      .map(([label, count]) => ({ label, count }))
      .sort((a, b) => b.count - a.count);
  };

  const columnFiltersWithCounts: Record<string, { label: string; count: number }[]> =
    labeledFilterColumns.reduce((acc: any, column: any) => {
      const key: string = Object.keys(column)[0];
      const value = column[key](getCountedLabels);
      acc[key] = value;
      return acc;
    }, {});

  const handleFilterChange = (key: string, value: string) => {
    let existingParams = extractExistingParams(searchParams);
    if (existingParams[key]) {
      if (existingParams[key].includes(value)) {
        existingParams[key] = existingParams[key].filter((v: string) => v !== value);
        if (existingParams[key]?.length === 0) {
          delete existingParams[key];
        }
      } else {
        existingParams[key].push(value);
      }
    }
    if (!existingParams[key]) {
      const currentValues = filters[key] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((currentValue: string) => currentValue !== value)
        : [...currentValues, value];
      const newFilters = { ...filters, [key]: updatedValues };
      existingParams = { ...existingParams, ...newFilters };
    }
    setSearchParams({ ...searchParams, ...existingParams });
  };

  const clearAllFilters = () => {
    const existingParams = extractExistingParams(searchParams);
    Object.keys(filters).forEach(key => {
      if (existingParams[key]) {
        delete existingParams[key];
      }
    });
    Object.keys(specialFilters).forEach(key => {
      if (existingParams[key]) {
        delete existingParams[key];
      }
    });
    console.log(existingParams);

    setSearchParams({ ...searchParams, ...existingParams });
  };

  const handleSpecialFilterChange = (key: keyof SpecialFiltersType): void => {
    const existingParams = extractExistingParams(searchParams);
    let updatedFilters: SpecialFiltersType = {
      ...specialFilters,
      [key]: !specialFilters[key],
      ...(key === 'oilOnly' ? { energyTransitionOnly: false } : {}),
      ...(key === 'energyTransitionOnly' ? { oilOnly: false } : {})
    };
    updatedFilters = { ...existingParams, ...updatedFilters };
    setSearchParams({ ...searchParams, ...updatedFilters });
  };

  const specialFiltersToRender: SpecialFilterOption[] = [
    {
      label: 'Oil Only',
      key: 'oilOnly',
      visible: true
    },
    {
      label: 'Energy Transition Only',
      key: 'energyTransitionOnly',
      visible: true
    }
  ];

  return {
    clearAllFilters,
    specialFiltersToRender,
    handleSpecialFilterChange,
    columnFiltersWithCounts,
    filters,
    handleFilterChange
  };
};

export default useTableFilters;
