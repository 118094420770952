import Paper from '@mui/material/Paper';
import { Box, Button, Typography } from '@mui/material';
import KeyDialog from './KeyDialog';
import useTokenGeneration from './useTokenGeneration';
import ApiKeyTable from './Table';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';

const TokenGenerationComponent = () => {
  const { apiKeys, open, setOpen, error } = useTokenGeneration();
  const { checkUserIsFree } = useUserDetails();
  const isPremiumFeatureShown = checkUserIsFree();
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 3,
        width: '100%'
      }}
    >
      <KeyDialog
        open={open}
        setOpen={setOpen}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant='body2'>Token Generation</Typography>
        <Button
          variant={'contained'}
          sx={{ pl: 2, pr: 2 }}
          onClick={() => {
            setOpen(!open);
          }}
          disabled={isPremiumFeatureShown}
        >
          Generate new token
        </Button>
      </Box>
      {isPremiumFeatureShown && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ py: 3 }}>
            GX Go Premium users can export data through our API and many other methods.
          </Typography>
          <UpgradeToPremiumButton />
        </Box>
      )}
      {!isPremiumFeatureShown && Boolean(error) && error}
      {!isPremiumFeatureShown && !error && <ApiKeyTable apiKeys={apiKeys} />}
    </Box>
  );
};

export default TokenGenerationComponent;
