import { Button, ButtonProps } from '@mui/material';
import { useState } from 'react';

interface DisableableButtonProps extends ButtonProps {
  clickCallback: () => void;
  disabledProp?: boolean;
}
export const LockingDisableButton = (props: DisableableButtonProps) => {
  const [disabled, setDisabled] = useState(false);
  const { clickCallback, disabledProp, ...buttonProps } = props;
  const children = props.children;
  return (
    <Button
      onClick={() => {
        setDisabled(true);
        clickCallback();
      }}
      {...buttonProps}
      disabled={disabledProp || disabled}
    >
      {children}
    </Button>
  );
};
